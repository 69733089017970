import "./FavouritesHeart.scss";
import React, { useEffect, useState } from "react";
import { addProductToCartOrFavourites, removeProductFromCartOrFavourites } from "Redux/slices/cartSlice";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useMobileResolution } from "Hooks/useMobileResolution";
import { useShow } from "Hooks/useShow";
import { addToWishlistGTM } from "Utils/analyticEvents";
import { HeartIcon } from "Svg/HeartIcon";
import { AddToFavoritesPopup } from "Components/AddToFavoritesPopup/AddToFavoritesPopup";
import { hasProductSizes } from "Ts/types/productType";
export const FavouritesHeart = ({ className, category = "", product, onClick }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    const { productsInFavourites } = useAppSelector((state) => state.cart);
    const { isMobileResolution } = useMobileResolution();
    const [favouritesIds, setFavouritesIds] = useState([]);
    const isAdded = favouritesIds.includes(product.id);
    useEffect(() => {
        setFavouritesIds(productsInFavourites.map((product) => product.productId));
    }, [productsInFavourites]);
    const [selectedSize, setSelectedSize] = useState("");
    useEffect(() => {
        var _a, _b;
        const chosenSize = (_a = productsInFavourites.find((productInFavourites) => productInFavourites.productId === product.id)) === null || _a === void 0 ? void 0 : _a.sizes;
        hasProductSizes(product) &&
            setSelectedSize((chosenSize && chosenSize[0]) || (product.sizes && ((_b = product.sizes[0]) === null || _b === void 0 ? void 0 : _b.normal_size)) || "");
    }, [product.id, productsInFavourites]);
    const onAddToFavourites = () => {
        dispatch(addProductToCartOrFavourites({
            newProducts: [{ productId: product.id, sizes: [selectedSize] }],
            location: "favourites",
            needSyncWithDB: !!user,
        }));
        hasProductSizes(product) && addToWishlistGTM({ product, category });
        onClick && onClick();
        closeSizeSelectionPopup();
    };
    const { open: isSizeSelectionPopupShown, onOpen: openSizeSelectionPopup, onClose: closeSizeSelectionPopup } = useShow();
    const onFavoriteClick = () => {
        var _a;
        if (isAdded) {
            dispatch(removeProductFromCartOrFavourites({
                removedProducts: [product.id],
                location: "favourites",
                needSyncWithDB: !!user,
            }));
            return;
        }
        if (hasProductSizes(product) && product.sizes && product.sizes.length <= 1) {
            setSelectedSize(((_a = product.sizes[0]) === null || _a === void 0 ? void 0 : _a.normal_size) || "");
            onAddToFavourites();
            return;
        }
        if (isSizeSelectionPopupShown) {
            return;
        }
        openSizeSelectionPopup();
    };
    return (React.createElement("div", { className: "favouritesHeart" + (className ? ` ${className}` : ""), onClick: onFavoriteClick },
        React.createElement(HeartIcon, { pathStyle: {
                stroke: isAdded ? "var(--blue-500)" : "",
                fill: isAdded ? "var(--blue-500)" : "",
            } }),
        React.createElement(AddToFavoritesPopup, { selectedSize: selectedSize, isMobileResolution: isMobileResolution, isSizeSelectionPopupShown: isSizeSelectionPopupShown, sizes: hasProductSizes(product) ? product.sizes : [], closeSizeSelectionPopup: closeSizeSelectionPopup, onAddToFavourites: onAddToFavourites, setSelectedSize: setSelectedSize })));
};
