import { useEffect, useState } from "react";
import { useAppSelector } from "./useAppSelector";
export const useMobileResolution = (width = 768) => {
    const { breakpointMatches, deviceInfo } = useAppSelector((state) => state.app);
    const [isMobileResolution, setIsMobileResolution] = useState(breakpointMatches[width] || deviceInfo.deviceType === "mobile");
    useEffect(() => {
        setIsMobileResolution(breakpointMatches[width]);
    }, [breakpointMatches[width]]);
    return { isMobileResolution };
};
