import "./OptionalCarousel.scss";
import React, { useEffect, useRef, useState } from "react";
import { fetchApi } from "Utils/fetchApi";
import { IntersectionObserverWrapper } from "Components/IntersectionObserverWrapper/IntersectionObserverWrapper";
import { GoodsCarousel } from "Components/GoodsCarousel/GoodsCarousel";
export const OptionalCarousel = ({ category, className, deps = [], location, pathName, query, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCarouselShown, setIsCarouselShown] = useState(false);
    const [goodsData, setGoodsData] = useState([]);
    const [title, setTitle] = useState("");
    useEffect(() => {
        setIsLoading(true);
        const queryParams = new URLSearchParams({ ...query, allSizes: "1", withSizes: "1" });
        isCarouselShown &&
            fetchApi({ method: "GET", url: pathName + `?${queryParams.toString()}` })
                .then(({ data }) => {
                setGoodsData(data.products || data.data);
                setTitle(data.title);
                setAttributes(data.attributes || null);
            })
                .finally(() => setIsLoading(false));
    }, [...deps, isCarouselShown]);
    const [attributes, setAttributes] = useState(null);
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (wrapperRef.current && attributes) {
            Object.entries(attributes).forEach(([name, value]) => {
                wrapperRef.current && wrapperRef.current.setAttribute(name, value);
            });
        }
    }, [wrapperRef.current, attributes]);
    if (!isLoading && goodsData.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "optionalCarousel" + (className ? ` ${className}` : ""), ref: wrapperRef },
        isLoading ? (React.createElement("div", { className: 'optionalCarousel__title_skeleton skeletonLoading' })) : (title && React.createElement("p", { className: 'optionalCarousel__title typography_title' }, title)),
        React.createElement("div", { className: 'optionalCarousel__slider' },
            React.createElement(IntersectionObserverWrapper, { querySelector: `.goodsCarousel_${category}`, observerCallback: () => setIsCarouselShown(true), children: React.createElement(GoodsCarousel, { carouselLocation: location, categoryOfGoodsData: goodsData, category: category, isLoading: isLoading, isRecommendation: true }) }))));
};
