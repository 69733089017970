import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import "./AdaptivePopup.scss";
import React from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CustomModal } from "Components/CustomModal/CustomModal";
export const AdaptivePopup = ({ children, onClose, contentClassName, drawerClassName = "", isMobileResolution, isPopupShown, modalClassName = "", title, titleClassName, withDefaultPaddinsModal = true, withHeader = true, }) => {
    const { breakpointMatches } = useAppSelector((state) => state.app);
    return (React.createElement(React.Fragment, null, breakpointMatches[600] || !!isMobileResolution ? (React.createElement(CustomDrawer, { rootClassName: "adaptivePopup__drawer customServiceDrawer_bottom" + (drawerClassName ? ` ${drawerClassName}` : ""), open: isPopupShown, placement: 'bottom', destroyOnClose: true, onClose: onClose },
        withHeader && (React.createElement("div", { className: "customServiceDrawer_bottom__title" +
                (isMobileResolution ? " customServiceDrawer_bottom__title_bottomSpace" : "") },
            title && (React.createElement("span", { className: "customServiceDrawer_bottom__title__text" +
                    (titleClassName ? ` ${titleClassName}` : " typography_tec_15_menu") }, title)),
            React.createElement(CloseIcon, { className: 'customServiceDrawer_bottom__title__closeIcon', size: 'small', withBackground: true, onClick: onClose }))),
        React.createElement("div", { className: "customServiceDrawer_bottom__content" + (contentClassName ? ` ${contentClassName}` : "") }, children))) : (React.createElement(CustomModal, { className: "adaptivePopup__modal" + (modalClassName ? ` ${modalClassName}` : ""), open: isPopupShown, defaultPaddings: withDefaultPaddinsModal, onClose: onClose },
        withHeader && (React.createElement("div", { className: 'customModal__content__header' },
            title && (React.createElement("span", { className: "customModal__content__header__title typography_h1" + (titleClassName ? ` ${titleClassName}` : "") }, title)),
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: onClose }))),
        React.createElement("div", { className: "customModal__content__body" + (contentClassName ? ` ${contentClassName}` : "") }, children)))));
};
