export function hasProductSizes(product) {
    return "sizes" in product;
}
export function hasProductCategory(product) {
    return "category" in product;
}
export function hasProductName(product) {
    var _a;
    return !!((_a = product === null || product === void 0 ? void 0 : product.brand) === null || _a === void 0 ? void 0 : _a.name);
}
export function hasProductSex(product) {
    return "sex" in product;
}
