import "./AddToFavoritesPopup.scss";
import React from "react";
import { AdaptivePopup } from "Components/AdaptivePopup/AdaptivePopup";
import { CustomRadio } from "Components/CustomRadio/CustomRadio";
import { HeartIcon } from "Svg/HeartIcon";
export const AddToFavoritesPopup = ({ isMobileResolution, isSizeSelectionPopupShown, selectedSize, sizes, closeSizeSelectionPopup, onAddToFavourites, setSelectedSize, }) => {
    return (React.createElement(AdaptivePopup, { contentClassName: '', isPopupShown: isSizeSelectionPopupShown, onClose: closeSizeSelectionPopup, isMobileResolution: isMobileResolution, title: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0430\u0437\u043C\u0435\u0440', titleClassName: "addToFavoritesPopup__sizesSelectionPopup__title" +
            (isMobileResolution ? " typography_tec_18_medium_semiBold" : " typography_tec_title") },
        React.createElement("div", { className: 'addToFavoritesPopup__sizesSelectionPopup__content' }, Array.isArray(sizes) &&
            sizes.map((size) => {
                const checked = size.normal_size === selectedSize;
                return (React.createElement("div", { key: size.id, onClick: () => setSelectedSize(size.normal_size) },
                    React.createElement("div", { className: 'customServiceDrawer_bottom__content__item addToFavoritesPopup__sizesSelectionPopup__content__item' },
                        React.createElement("span", { className: 'typography_tec_16_default' }, size.normal_size),
                        React.createElement(CustomRadio, { id: size.id, checked: checked }))));
            })),
        React.createElement("button", { className: "addToFavoritesPopup__sizesSelectionPopup__button customBtn customBtn_contained" +
                (isMobileResolution ? "" : " customBtn_large"), onClick: onAddToFavourites },
            React.createElement(HeartIcon, { pathStyle: {
                    stroke: "white",
                    fill: "transparent",
                } }),
            React.createElement("span", { className: 'typography_tec_16_medium' }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0432 \u0438\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435"))));
};
