import React from "react";
import { DiscountIcon } from "Svg/DiscountIcon";
export const DiscountNotification = () => {
    return (React.createElement("div", { className: 'notifications__category__item' },
        React.createElement("div", { className: 'notifications__category__item__icon' },
            React.createElement(DiscountIcon, null)),
        React.createElement("div", { className: 'notifications__category__item__text' },
            React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u0441\u043A\u0438\u0434\u043A\u0443!"),
            React.createElement("span", { className: 'typography_captionTxt' }, "\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043A\u043B\u0438\u0435\u043D\u0442, \u0412\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u0441\u043A\u0438\u0434\u043A\u0443 15% \u043D\u0430 \u043F\u0435\u0440\u0432\u044B\u0439 \u0437\u0430\u043A\u0430\u0437."))));
};
