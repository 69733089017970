import "./App.scss";
import React, { useEffect, Suspense, useState, useRef } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import { useDispatch } from "react-redux";
import { addNotification, changeAppNotifyVisibility, changeCity, changeDiscountIncrease, changeFlexGapSupportStatus, changeFooterLinks, changeIpFriendlinessStatus, identifyDevice, removeNotificaionCategory, rewriteUnvisitedOrders, saveBreakpointMatches, saveDefaultFooterLinks, saveOptionalBlocks, savePromoActions, } from "Redux/slices/appSlice";
import { addProductToCartOrFavourites, changeStatusOfFavouritesIndicator, rewriteCartOrFavourites, } from "Redux/slices/cartSlice";
import { changeUserTypeDefined, saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { changeSwitchableFilter } from "Redux/slices/catalogFiltersSlice";
import { useAppSelector } from "Hooks/useAppSelector";
import { useLogout } from "Hooks/useLogout";
import { useIncreaseNotification } from "Hooks/useIncreaseNotification";
import { apiUrl, hostName } from "Static/variables";
import { getCookie } from "Utils/getCookie";
import { deleteCookie } from "Utils/deleteCookie";
import { getQueryVariable, getQueryVariableFromUrl } from "Utils/getQueryVariable";
import { getUniqueAndConcatedLocalData } from "Utils/getUniqueAndConcatedLocalData";
import { checkFlexGap } from "Utils/checkFlexGap";
import { searchTrackingForYM } from "Utils/searchTrackingForYM";
import localStorage from "Utils/localStorage";
import { getISO } from "Utils/getISO";
import { saveUtmLabels } from "Utils/saveUtmLabels";
import { fetchApi } from "Utils/fetchApi";
import { saveCookie } from "Utils/saveCookie";
import { AppNotify } from "Components/AppNotify/AppNotify";
import { AuthModal } from "Components/AuthModal/AuthModal";
import { ClientWidget } from "Components/ClientWidget/ClientWidget";
import { Footer } from "Components/Footer/Footer";
import { Header } from "Components/Header/Header";
import { Loader } from "Components/Loader/Loader";
import { ManagerWidget } from "Components/ManagerWidget/ManagerWidget";
import { MobileNavigation } from "Components/MobileNavigation/MobileNavigation";
import { NotFound404 } from "Components/NotFound404/NotFound404";
import { Notifications } from "Components/Notifications/Notifications";
import { PromoAction } from "Components/PromoAction/PromoAction";
import { Snackbar } from "Components/Snackbar/Snackbar";
import { AuthWrapper } from "Components/AuthWrapper/AuthWrapper";
import { useReviewNotification } from "Hooks/useReviewNotification";
const Award = loadable(() => import("Pages/Award/Award"));
const Awards = loadable(() => import("Pages/Awards/Awards"));
const BrandPromo = loadable(() => import("Pages/BrandPromo/BrandPromo"));
const BrandWall = loadable(() => import("Pages/BrandWall/BrandWall"));
const Cart = loadable(() => import("Pages/Cart/Cart"));
const Catalog = loadable(() => import("Pages/Catalog/Catalog"));
const Categories = loadable(() => import("Pages/Categories/Categories"));
const CertificateRedirect = loadable(() => import("Pages/CertificateRedirect/CertificateRedirect"));
const Certificates = loadable(() => import("Pages/Certificates/Certificates"));
const CertificateSelection = loadable(() => import("Pages/CertificateSelection/CertificateSelection"));
const Checkout = loadable(() => import("Pages/Checkout/Checkout"));
const CheckoutCertificate = loadable(() => import("Pages/CheckoutCertificate/CheckoutCertificate"));
const CurrentPost = loadable(() => import("Pages/CurrentPost/CurrentPost"));
const Favourites = loadable(() => import("Pages/Favourites/Favourites"));
const GiftCertificate = loadable(() => import("Pages/GiftCertificate/GiftCertificate"));
const Home = loadable(() => import("Pages/Home/Home"));
const Invitations = loadable(() => import("Pages/Invitations/Invitations"));
const LoyaltyProgram = loadable(() => import("Pages/LoyaltyProgram/LoyaltyProgram"));
const ManagerLinks = loadable(() => import("Pages/ManagerLinks/ManagerLinks"));
const MyAddresses = loadable(() => import("Pages/MyAddresses/MyAddresses"));
const MyDetails = loadable(() => import("Pages/MyDetails/MyDetails"));
const MyDiscount = loadable(() => import("Pages/MyDiscount/MyDiscount"));
const Order = loadable(() => import("Pages/Order/Order"));
const OrderRedirect = loadable(() => import("Pages/OrderRedirect/OrderRedirect"));
const OrderPayment = loadable(() => import("Pages/OrderPayment/OrderPayment"));
const Orders = loadable(() => import("Pages/Orders/Orders"));
const PersonalArea = loadable(() => import("Pages/PersonalArea/PersonalArea"));
const Product = loadable(() => import("Pages/Product/Product"));
const SoldOut = loadable(() => import("Pages/SoldOut/SoldOut"));
const Promocodes = loadable(() => import("Pages/Promocodes/Promocodes"));
const Purchases = loadable(() => import("Pages/Purchases/Purchases"));
const RegistrationLanding = loadable(() => import("Pages/RegistrationLanding/RegistrationLanding"));
const ServicePages = loadable(() => import("Pages/ServicePages/ServicePages"));
const SiteMap = loadable(() => import("Pages/SiteMap/SiteMap"));
const Sizes = loadable(() => import("Pages/Sizes/Sizes"));
const Subscriptions = loadable(() => import("Pages/Subscriptions/Subscriptions"));
const Unsubscriptions = loadable(() => import("Pages/Unsubscriptions/Unsubscriptions"));
export const App = () => {
    const dispatch = useDispatch();
    const logout = useLogout();
    const { isHeaderShown, deviceInfo, city, notifications, promoActions } = useAppSelector((state) => state.app);
    const { user, isUserTypeDefined } = useAppSelector((state) => state.userInfo);
    useIncreaseNotification();
    useReviewNotification();
    useEffect(() => {
        const localToken = localStorage.getItem("token");
        const queryToken = getQueryVariable("access-token");
        const user_id = getCookie("user_id");
        const hashcode = getCookie("hashcode");
        if (localToken) {
            const tokenExp = JSON.parse(atob(localToken.split(".")[1])).exp * 1000 - 3.6e6;
            tokenExp > Date.now()
                ? fetchApi({ method: "GET", url: "/user" }).then((res) => saveRespUserData(res.data, localToken))
                : fetch(apiUrl + "/auth/refresh", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Authorization: localToken,
                    },
                }).then(async (res) => {
                    const refreshData = await res.json();
                    if (refreshData.status === 401) {
                        logout();
                        location.reload();
                    }
                    else {
                        saveRespUserData(refreshData.user, refreshData.token);
                    }
                });
        }
        else if (queryToken) {
            fetchApi({ method: "GET", url: "/user", headers: { Authorization: queryToken } }).then((res) => saveRespUserData(res.data, queryToken));
        }
        else if (user_id && hashcode) {
            fetchApi({ method: "POST", url: "/auth/by-hashcode", body: { user_id, hashcode } }).then((res) => {
                saveRespUserData(res.data.user, res.data.token);
                deleteCookie("user_id");
                deleteCookie("hashcode");
            });
        }
        else {
            dispatch(changeUserTypeDefined(true));
            dispatch(rewriteUnvisitedOrders(0));
        }
        sessionStorage.removeItem("fromDiscountPromo");
    }, []);
    useEffect(() => {
        var _a, _b;
        if (isUserTypeDefined && user) {
            dispatch(rewriteCartOrFavourites({
                newProducts: user.cart,
                location: "cart",
                needSyncWithDB: false,
            }));
            dispatch(rewriteCartOrFavourites({
                newProducts: user.wishlist,
                location: "favourites",
                needSyncWithDB: false,
            }));
            if (user.role.id === "1") {
                YMIntevalId.current = setInterval(() => "yaCounter10626637" in window && setYMInstance(window.yaCounter10626637), 1000);
            }
            const admitad_uid = getCookie("admitad_uid");
            user.admitad_uid !== admitad_uid && fetchApi({ method: "POST", url: "/user/update", body: { admitad_uid } });
            dispatch(changeSwitchableFilter({
                name: "enableMPlaceProducts",
                value: (_b = (_a = user.catalogInitialFilters) === null || _a === void 0 ? void 0 : _a.enableMarketplace) !== null && _b !== void 0 ? _b : true,
            }));
        }
    }, [isUserTypeDefined]);
    const saveRespUserData = (userData, token) => {
        dispatch(saveAuthToken(token));
        dispatch(saveUserData(userData));
        (userData === null || userData === void 0 ? void 0 : userData.city) && userData.city.city_id !== city.city_id && dispatch(changeCity(userData.city));
        dispatch(changeUserTypeDefined(true));
        saveCookie("isAuth", "true", { domain: `.${hostName}`, "max-age": 201600 });
    };
    useEffect(() => {
        window.addEventListener("storage", (event) => {
            const key = event.key;
            if (!document.hasFocus()) {
                if (key === "cart" || key === "favourites") {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: getUniqueAndConcatedLocalData(JSON.parse(event.newValue || "[]")),
                        location: key,
                        needSyncWithDB: false,
                    }));
                }
                if (key === "token") {
                    window.location.reload();
                }
                if (key === "showFavouritesIndicator") {
                    dispatch(changeStatusOfFavouritesIndicator(!!event.newValue));
                }
            }
        });
        dispatch(identifyDevice());
        !JSON.parse(localStorage.getItem("city") || "{}").country &&
            !location.pathname.startsWith("/city") &&
            fetchApi({ method: "GET", url: "/city/closest" }).then((res) => dispatch(changeCity(res.data)));
        fetchApi({ method: "GET", url: "/settings" }).then(({ data }) => {
            const { brandsForFooter, discountIncrease, isFriendlyIp, optionalBlocks } = data;
            dispatch(changeIpFriendlinessStatus(isFriendlyIp));
            const isWarningShown = !!notifications.find((n) => n.category === "vpnWarning");
            const lastDateStamp = localStorage.getItem("lastDateStampOfVpnWarning");
            const isWarningNeedShow = !!lastDateStamp ? lastDateStamp < getISO("date") : true;
            if (isWarningShown && isFriendlyIp) {
                dispatch(removeNotificaionCategory("vpnWarning"));
                localStorage.removeItem("lastDateStampOfVpnWarning");
            }
            else if (!isWarningShown && !isFriendlyIp && isWarningNeedShow) {
                dispatch(addNotification({ category: "vpnWarning", id: Date.now().toString(), content: "" }));
                localStorage.setItem("lastDateStampOfVpnWarning", getISO("date"));
            }
            dispatch(changeDiscountIncrease(discountIncrease));
            optionalBlocks.length > 0 && dispatch(saveOptionalBlocks(optionalBlocks));
            if (brandsForFooter.length > 0) {
                dispatch(changeFooterLinks(brandsForFooter));
                dispatch(saveDefaultFooterLinks(brandsForFooter));
            }
        });
        dispatch(changeFlexGapSupportStatus(checkFlexGap()));
        const utm_source = getQueryVariableFromUrl(location.pathname + location.search, "utm_source");
        if (utm_source) {
            saveCookie("utm_source", utm_source, { expires: new Date(Date.now() + 7.776e9) });
            if (utm_source === "admitad") {
                const admitad_uid = getQueryVariableFromUrl(location.pathname + location.search, "admitad_uid");
                admitad_uid && saveCookie("admitad_uid", admitad_uid, { expires: new Date(Date.now() + 7.776e9) });
            }
        }
    }, []);
    useEffect(() => {
        deviceInfo.deviceType === "mobile" && showAppNotify();
    }, [deviceInfo]);
    const showAppNotify = () => {
        const prevTimeAppNotify = localStorage.getItem("appNotify");
        const currentTimeAppNotify = Math.round(new Date().getTime() / 1000);
        if (deviceInfo.deviceOS !== "iPhone" &&
            deviceInfo.deviceOS !== "iPad" &&
            (!prevTimeAppNotify || currentTimeAppNotify - +prevTimeAppNotify > 0)) {
            localStorage.setItem("appNotify", String(currentTimeAppNotify));
            dispatch(changeAppNotifyVisibility(true));
        }
    };
    useEffect(() => {
        const mutationObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                const target = mutation.target;
                if (target.classList.contains("digi-disable-scroll")) {
                    searchTrackingForYM();
                }
            });
        });
        mutationObserver.observe(document.body, {
            attributes: true,
        });
    }, []);
    useEffect(() => {
        isUserTypeDefined &&
            fetchApi({ method: "GET", url: "/promo-actions" }).then(({ data }) => dispatch(savePromoActions(data)));
    }, [user === null || user === void 0 ? void 0 : user.id, isUserTypeDefined]);
    useEffect(() => {
        [1440, 1280, 1080, 1023, 960, 768, 640, 600, 374].forEach((width) => {
            const match = window.matchMedia(`(max-width: ${width}px)`);
            function callback(eventList) {
                dispatch(saveBreakpointMatches({ [width]: eventList.matches }));
            }
            !!MediaQueryList.prototype.addEventListener
                ? MediaQueryList.prototype.addEventListener.apply(match, ["change", callback])
                : match.addListener(callback);
        });
    }, []);
    const YMIntevalId = useRef(null);
    const [YMInstance, setYMInstance] = useState(null);
    useEffect(() => {
        if (YMInstance && YMIntevalId.current && user) {
            YMInstance.setUserID(user.id);
            YMInstance.params({ customLvl_1: { customLvl_2: { UserID: user.id } } });
            clearInterval(YMIntevalId.current);
        }
        return () => {
            YMIntevalId.current && clearInterval(YMIntevalId.current);
        };
    }, [YMInstance]);
    useEffect(() => {
        if (isUserTypeDefined && location.search.includes("utm_")) {
            const queryParams = location.search.slice(1).split("&");
            let utmLabels = "";
            queryParams.forEach((el) => {
                if (el.startsWith("utm_"))
                    utmLabels += el + "&";
            });
            const currentLabels = { time: getISO(), utmLabels: utmLabels.slice(0, -1) };
            saveUtmLabels(!!user, currentLabels);
            user &&
                fetchApi({
                    method: "POST",
                    url: "/user/save-utm",
                    body: { labels: [currentLabels] },
                    hideErrorSnackbar: true,
                });
        }
    }, [isUserTypeDefined]);
    useEffect(() => {
        const refLinkId = getQueryVariable("ref");
        isUserTypeDefined && !!refLinkId && localStorage.setItem("refLinkId", refLinkId);
    }, [isUserTypeDefined]);
    const protectedRoutes = [
        { path: "/awards/", element: React.createElement(Awards, null) },
        { path: "/awards/:awardId/", element: React.createElement(Award, null) },
        { path: "/certificateRedirect/:certificateHash/", element: React.createElement(CertificateRedirect, null) },
        { path: "/certificates/", element: React.createElement(Certificates, null) },
        { path: "/checkoutCertificate/", element: React.createElement(CheckoutCertificate, null) },
        { path: "/giftCertificate/:certificateNumber/", element: React.createElement(GiftCertificate, null) },
        { path: "/invitations/", element: React.createElement(Invitations, null) },
        { path: "/myAddresses/", element: React.createElement(MyAddresses, null) },
        { path: "/myDetails/", element: React.createElement(MyDetails, null) },
        { path: "/myDiscount/", element: React.createElement(MyDiscount, null) },
        { path: "/orderRedirect/:orderId/", element: React.createElement(OrderRedirect, null) },
        { path: "/orders/", element: React.createElement(Orders, null) },
        { path: "/orders/:token/", element: React.createElement(Order, null) },
        { path: "/orderPayment/:token/", element: React.createElement(OrderPayment, null) },
        { path: "/personalArea/", element: React.createElement(PersonalArea, null) },
        { path: "/promocodes/", element: React.createElement(Promocodes, null) },
        { path: "/purchases/", element: React.createElement(Purchases, null) },
        { path: "/subscriptions/", element: React.createElement(Subscriptions, null) },
    ];
    return (React.createElement(BrowserRouter, null,
        React.createElement(AppNotify, null),
        React.createElement(AuthModal, null),
        React.createElement("div", { "data-popmechanic-embed": '103301' }),
        isHeaderShown && React.createElement("div", { className: 'main__wrapper__header__attic__background' }),
        React.createElement("div", { className: 'main__wrapper', "data-is_header_shown": isHeaderShown },
            React.createElement(Header, null),
            isUserTypeDefined && (React.createElement(React.Fragment, null,
                React.createElement(ManagerWidget, null),
                React.createElement(ClientWidget, null),
                promoActions.map((promoAction) => (React.createElement(PromoAction, { key: promoAction.name, promoActionData: promoAction }))),
                React.createElement("main", { className: 'app__wrapper', "data-is_header_shown": isHeaderShown },
                    React.createElement(Notifications, null),
                    React.createElement(Suspense, { fallback: React.createElement(Loader, { className: 'app__wrapper__suspenseLoader' }) },
                        React.createElement(Routes, null,
                            React.createElement(Route, { path: '/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/men/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/women/', element: React.createElement(Home, null) }),
                            React.createElement(Route, { path: '/categories/', element: React.createElement(Categories, null) }),
                            React.createElement(Route, { path: '/categories/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/categories/:gender/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/brands/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/brands/:slug/:category/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/brands/:slug/:gender/:category/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/tags/:tag/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/specials/:slug/', element: React.createElement(Catalog, null) }),
                            React.createElement(Route, { path: '/products/:productId/', element: React.createElement(Product, null) }),
                            React.createElement(Route, { path: '/soldout/:productId/', element: React.createElement(SoldOut, null) }),
                            React.createElement(Route, { path: '/brands/', element: React.createElement(BrandWall, null) }),
                            React.createElement(Route, { path: '/cart/', element: React.createElement(Cart, null) }),
                            React.createElement(Route, { path: '/checkout/', element: React.createElement(Checkout, null) }),
                            React.createElement(Route, { path: '/service/consentToProcessing/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/service/certificateRegulations/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/service/about/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/service/accountDeletion/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/sections/shipping/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/sections/returns/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/faq/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/feed/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/articles/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/city/:cityName/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/payment/', element: React.createElement(ServicePages, null) }),
                            React.createElement(Route, { path: '/news/:url/', element: React.createElement(CurrentPost, { postType: 'news' }) }),
                            React.createElement(Route, { path: '/articles/:url/', element: React.createElement(CurrentPost, { postType: 'articles' }) }),
                            React.createElement(Route, { path: '/managerLinks/', element: React.createElement(ManagerLinks, null) }),
                            React.createElement(Route, { path: '/map/', element: React.createElement(SiteMap, null) }),
                            React.createElement(Route, { path: '/404/', element: React.createElement(NotFound404, null) }),
                            React.createElement(Route, { path: '/favourites/', element: React.createElement(Favourites, null) }),
                            React.createElement(Route, { path: '/sizes/', element: React.createElement(Sizes, null) }),
                            React.createElement(Route, { path: '/loyaltyProgram/', element: React.createElement(LoyaltyProgram, null) }),
                            React.createElement(Route, { path: '/certificateSelection/', element: React.createElement(CertificateSelection, null) }),
                            React.createElement(Route, { path: '/reg/', element: React.createElement(RegistrationLanding, null) }),
                            React.createElement(Route, { path: '/brandPromo/:brandName/', element: React.createElement(BrandPromo, null) }),
                            React.createElement(Route, { path: '/unsubscriptions/', element: React.createElement(Unsubscriptions, null) }),
                            protectedRoutes.map((props) => (React.createElement(Route, { ...props, element: React.createElement(AuthWrapper, { children: props.element }), key: props.path }))),
                            React.createElement(Route, { path: '*', element: React.createElement(NotFound404, null) })))),
                React.createElement(MobileNavigation, null),
                React.createElement(Snackbar, null),
                React.createElement(Footer, null))))));
};
