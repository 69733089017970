import "./CustomRadio.scss";
import React from "react";
export const CustomRadio = ({ checked, className, darkMode, disabled, id, labelClassName = "", labelText, name, onChange, size = "normal", style, }) => {
    const onChangeHandler = () => {
        onChange && onChange(id);
    };
    return (React.createElement("div", { className: className ? className : "", style: style },
        React.createElement("input", { className: "customRadio__input" + (size !== "normal" ? ` customRadio__input_${size}` : ""), checked: checked, disabled: disabled, id: id, name: name, type: 'radio', onChange: onChangeHandler }),
        React.createElement("label", { htmlFor: id, className: `${labelClassName} ${darkMode ? "blackLabel" : ""}`.trim() }, typeof labelText === "string" ? React.createElement("span", { className: 'typography_tec_16_default' }, labelText) : labelText)));
};
