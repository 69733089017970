import "./PseudoSearchInput.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { SearchIcon } from "Svg/SearchIcon";
import { isBrowser } from "Utils/isBrowser";
export const PseudoSearchInput = ({ className }) => {
    const { isFriendlyIp } = useAppSelector((state) => state.app);
    const [isAnyQueryScriptLoad, setIsAnyQueryScriptLoad] = useState(isBrowser() && !!(window === null || window === void 0 ? void 0 : window.digiCustom) ? false : true);
    useEffect(() => {
        if (isAnyQueryScriptLoad) {
            const script = document.createElement("script");
            script.src = "https://cdn.diginetica.net/2648/client.js";
            script.async = true;
            script.onload = () => setIsAnyQueryScriptLoad(false);
            document.body.append(script);
        }
    }, []);
    return (React.createElement("div", { className: "pseudoSearchInput AnyQuery__control" +
            (isAnyQueryScriptLoad ? " pseudoSearchInput_disabled" : "") +
            (className ? ` ${className}` : ""), "data-is_friendly_ip": isFriendlyIp },
        React.createElement("p", { className: 'pseudoSearchInput__placeholder typography_tec_buttonM' }, isAnyQueryScriptLoad ? "Загрузка.." : "Поиск"),
        React.createElement("div", { className: 'pseudoSearchInput__searchBtn' },
            React.createElement(SearchIcon, { className: 'pseudoSearchInput__searchBtn__icon' }))));
};
