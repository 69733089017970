import "./CustomDropdown.scss";
import { createPortal } from "react-dom";
import React, { useEffect, useRef, useState } from "react";
export const CustomDropdown = ({ children, getDropdownContainer, menu, onOpenChange, open, overlayClassName, placement = "bottomRight", trigger, }) => {
    var _a;
    const [isOverlayShown, setIsOverlayShown] = useState(false);
    const [dropdownContainer, setDropdownContainer] = useState(null);
    const overlayRef = useRef(null);
    const triggerRef = useRef(null);
    const isFirstRender = useRef(true);
    useEffect(() => {
        var _a;
        const overlay = overlayRef.current;
        if (overlay) {
            onOpenChange && onOpenChange(isOverlayShown);
            if (isOverlayShown) {
                overlay.classList.add("customDropdown__overlay_visible");
                (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && document.body.addEventListener("click", outsideClickHandler.current);
                document.body.addEventListener("keydown", escapeHandler.current);
                (_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
            else {
                overlay.classList.remove("customDropdown__overlay_visible");
                removeListeners();
            }
        }
    }, [isOverlayShown]);
    useEffect(() => {
        setIsOverlayShown(!!open);
    }, [open]);
    useEffect(() => {
        var _a;
        let dropdownContainer = getDropdownContainer && getDropdownContainer(triggerRef.current);
        if (!dropdownContainer) {
            dropdownContainer = ((_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.children[0]) || null;
        }
        setDropdownContainer(dropdownContainer);
        return () => removeListeners();
    }, []);
    const escapeHandler = useRef((e) => {
        e.code === "Escape" && setIsOverlayShown(false);
    });
    const outsideClickHandler = useRef((e) => {
        triggerRef.current && !triggerRef.current.contains(e.target) && setIsOverlayShown(false);
    });
    const removeListeners = () => {
        document.body.removeEventListener("click", outsideClickHandler.current);
        document.body.removeEventListener("keydown", escapeHandler.current);
    };
    const menuItemHandler = (e, onClick, isDisabled) => {
        if (onClick && !isDisabled) {
            onClick(e);
            setTimeout(() => setIsOverlayShown(false), 150);
        }
    };
    const overlayClickHandler = (e) => {
        if (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) {
            if (isFirstRender.current)
                isFirstRender.current = false;
            e.target !== e.currentTarget && setIsOverlayShown((prev) => !prev);
        }
    };
    const overlayHoverHandler = (e) => {
        if (trigger === null || trigger === void 0 ? void 0 : trigger.includes("hover")) {
            if (isFirstRender.current)
                isFirstRender.current = false;
            const triggerNode = e.currentTarget;
            if (e.target === triggerNode) {
                const mouseMoveHandler = (e) => {
                    if (triggerNode.contains(e.relatedTarget))
                        setIsOverlayShown(true);
                    triggerNode.removeEventListener("mouseout", mouseMoveHandler);
                };
                triggerNode.addEventListener("mouseout", mouseMoveHandler);
            }
            else {
                setIsOverlayShown(true);
            }
            const mouseLeaveHandler = () => {
                const closeTimeout = setTimeout(() => {
                    setIsOverlayShown(false);
                    triggerNode.removeEventListener("mouseleave", mouseLeaveHandler);
                }, 200);
                const enterHandler = () => {
                    clearTimeout(closeTimeout);
                    triggerNode.removeEventListener("mouseenter", enterHandler);
                };
                triggerNode.addEventListener("mouseenter", enterHandler);
            };
            triggerNode.addEventListener("mouseleave", mouseLeaveHandler);
        }
    };
    return (React.createElement(React.Fragment, null,
        dropdownContainer &&
            !isFirstRender.current &&
            createPortal(React.createElement("div", { ref: overlayRef, className: `customDropdown__overlay customDropdown__overlay_${placement} ` +
                    (isOverlayShown ? " customDropdown__overlay_visible" : "") +
                    (overlayClassName ? ` ${overlayClassName}` : "") },
                React.createElement("ul", { className: (menu === null || menu === void 0 ? void 0 : menu.className) ? menu === null || menu === void 0 ? void 0 : menu.className : "" }, (_a = menu === null || menu === void 0 ? void 0 : menu.items) === null || _a === void 0 ? void 0 : _a.map(({ className, disabled, key, onClick, label }) => (React.createElement("li", { className: className, "data-disabled": disabled, key: key, onClick: (e) => menuItemHandler(e, onClick, disabled) }, label))))), dropdownContainer),
        React.createElement("div", { className: 'customDropdown__trigger', ref: triggerRef, onClick: overlayClickHandler, onMouseEnter: overlayHoverHandler }, children)));
};
