import { createSlice } from "@reduxjs/toolkit";
const initialSelectedFilters = {
    enableMPlaceProducts: true,
    inStock: true,
};
const initialState = {
    availableFilters: null,
    categoriesNames: [],
    switchableFilters: initialSelectedFilters,
    filtersData: null,
    inStockFilter: true,
    pricesFilter: null,
    selectedFilters: null,
    selectedFiltersCount: 0,
    subCategories: [],
};
export const catalogFiltersSlice = createSlice({
    name: "catalogFilters",
    initialState,
    reducers: {
        saveAllFilters(state, action) {
            state.filtersData = action.payload;
        },
        saveCategoriesNames(state, action) {
            state.categoriesNames = action.payload;
        },
        saveAvailableFilters(state, action) {
            state.availableFilters = action.payload;
        },
        saveSubCategories(state, action) {
            state.subCategories = action.payload;
        },
        changeSelectedFilters(state, action) {
            state.selectedFilters = action.payload;
        },
        changeSwitchableFilter(state, action) {
            const { name, value } = action.payload;
            state.switchableFilters[name] = value;
        },
        changeSelectedFiltersCount(state, action) {
            state.selectedFiltersCount = action.payload;
        },
        savePricesFilter(state, action) {
            state.pricesFilter = action.payload;
        },
    },
});
export const { saveAllFilters, saveCategoriesNames, saveAvailableFilters, saveSubCategories, savePricesFilter, changeSelectedFilters, changeSelectedFiltersCount, changeSwitchableFilter, } = catalogFiltersSlice.actions;
export default catalogFiltersSlice.reducer;
