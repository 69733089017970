import "./ClientWidget.scss";
import React, { useMemo } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { useShow } from "Hooks/useShow";
import { WA_number } from "Static/variables";
import { ChatSvg } from "./svg/ChatSvg";
import { PhoneFillIcon } from "Svg/PhoneFillIcon";
import { TelegramSvg } from "./svg/TelegramSvg";
import { PhoneIcon } from "Svg/PhoneIcon";
import { CustomDropdown } from "Components/CustomDropdown/CustomDropdown";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { DEFAULT_WIDGET_MESSAGE } from "./constants";
import { getCookie } from "Utils/getCookie";
export const ClientWidget = () => {
    var _a;
    const { deviceInfo, breakpointMatches } = useAppSelector((state) => state.app);
    const onDropDownMenuItemClick = (type) => () => {
        let link = "";
        let target = "_blank";
        switch (type) {
            case "telegram":
                link = "https://t.me/lsnetru_chat_bot";
                break;
            case "viber":
                link = `viber://pa?chatURI=lsnetru&text=${DEFAULT_WIDGET_MESSAGE}`;
            case "whatsapp":
                const yandexUserId = localStorage.getItem("_ym_uid") || getCookie("_ym_uid") || "";
                link = `https://wa.me/${WA_number}?text=Заявка ${yandexUserId.replace(/"/g, "")}:`;
                break;
            case "call":
                link = "tel:+74994553983";
                target = "_self";
                break;
        }
        window.open(link, target);
        closeDrawer();
    };
    const { open: isDrawerShown, onOpen: openDrawer, onClose: closeDrawer } = useShow();
    const dropDownMenuItems = useMemo(() => {
        return {
            className: "clientWidgetDropDown__menuItems",
            items: [
                {
                    key: "whatsapp",
                    label: (React.createElement(React.Fragment, null,
                        React.createElement(ChatSvg, { fill: 'var(--grey-800)' }),
                        "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 WhatsApp")),
                    onClick: onDropDownMenuItemClick("whatsapp"),
                },
                {
                    key: "telegram",
                    label: (React.createElement(React.Fragment, null,
                        React.createElement(TelegramSvg, { fill: 'var(--grey-800)' }),
                        "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 Telegram")),
                    onClick: onDropDownMenuItemClick("telegram"),
                },
                {
                    key: "call",
                    label: (React.createElement(React.Fragment, null,
                        React.createElement(PhoneIcon, { fill: 'var(--grey-800)' }),
                        "\u041F\u043E\u0437\u0432\u043E\u043D\u0438\u0442\u044C \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u043D\u0442\u0443")),
                    onClick: onDropDownMenuItemClick("call"),
                },
            ],
        };
    }, []);
    const isDesktop = deviceInfo.deviceType === "desktop" && !breakpointMatches[768];
    if (isDesktop) {
        return (React.createElement(CustomDropdown, { overlayClassName: 'clientWidgetDropDown', placement: 'topLeft', trigger: ["hover"], menu: dropDownMenuItems },
            React.createElement("div", { className: 'clientWidget' },
                React.createElement(PhoneFillIcon, null))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'clientWidget', onClick: openDrawer },
            React.createElement(PhoneFillIcon, null)),
        React.createElement(CustomDrawer, { rootClassName: 'clientWidget__drawer customServiceDrawer_bottom', placement: 'bottom', open: isDrawerShown, destroyOnClose: true, onClose: closeDrawer, headerStyle: { padding: 0, borderBottom: "none", overflow: "hidden" }, title: React.createElement("div", { className: 'clientWidget__drawer__title customServiceDrawer_bottom__title' },
                React.createElement("span", { className: 'typography_tec_18_medium_semiBold' }, "\u0417\u0430\u0434\u0430\u0442\u044C \u0432\u043E\u043F\u0440\u043E\u0441"),
                React.createElement(CloseIcon, { className: 'customServiceDrawer_bottom__title__closeIcon', onClick: closeDrawer })) },
            React.createElement("div", { className: 'clientWidget__drawer__content' },
                React.createElement("ul", { className: dropDownMenuItems.className }, (_a = dropDownMenuItems === null || dropDownMenuItems === void 0 ? void 0 : dropDownMenuItems.items) === null || _a === void 0 ? void 0 : _a.map(({ key, onClick, label }) => (React.createElement("li", { key: key, onClick: onClick }, label))))))));
};
